*, :focus {
  outline: none!important;
  box-shadow: none !important;
}

h2 {
  width: calc(100% - 30px);
  margin-left: 15px;
  border-bottom: 1px solid #ccc;
  font-size: 1.9em;
}

.customTooltip .tooltip-inner {
  max-width: 500px!important;
}

.dashboard h5 {
  width: calc(100% - 30px);
  margin-left: 15px;
  border-bottom: 1px solid #ccc;
  font-size: 1.3em;
}

.dashboard h6 {
  width: calc(100% - 105px);
  display: inline-block;
  vertical-align: top;
  text-transform: none;
}

.addTimer h6 span {
  width: 100%;
  display: inline-block;
  font-size: 13.5px !important;
}

.dashboard h6 span {
  width: 100%;
  display: inline-block;
  font-size: 17px;
}

.dashboard .c-footer {
  margin-top: 5px;
}

.table .form-control::placeholder {
  text-align: center;
}

.hideAddition .titleHead {
  position: relative;
  color: #eeeeee;
}

.hideAddition .titleHead:after {
  background: #eee;
  content: "Hidden Products";
  position: absolute;
  left: 0px;
  top: 0px;
  color: rgb(85, 85, 85);
}

.hideAddition .products.count {
  left: 205px;
}

.ps__rail-y {
  display: none!important;
}

.jss1 .jss7 {
  overflow-y: auto!important;
}

.Dashboard-wrapper-1 .Dashboard-mainPanel-2 {
  overflow-y: auto!important;
}

header {
  padding-left: 5px !important;
  margin-right: 15px;
  width: calc(100% - 15px)!important;
  z-index: 999!important;
}

header a[role="button"] {
  padding-left: 10px!important;
}

footer a {
  color: #d81b60!important;
}

header button:hover {
  opacity: .6;
}

.Sidebar-itemText-30 {
  font-weight: 600 !important;
}

.card-header {
  background: #eee!important;
}

.card {
  border: none!important;
}

.card-header .form-control {
  border-radius: 25px;
  padding-left: 20px !important;
}

.card table thead {
  background: #d81b60;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(233, 30, 99, .4);
  color: #fff;
}

.card .table th {
  border-top: none!important;
  border-bottom: none!important;
  padding: .5em!important;
}

.card .table tbody tr.dx-g-bs4-cursor-pointer td {
  /* font-weight: 600!important; */
  font-size: 15px!important;
  padding: .75rem!important;
}

.card .table tbody tr.dx-g-bs4-cursor-pointer:hover {
  box-shadow: 0 5px 5px 0px rgba(0, 0, 0, .14), 4px 0px 8px 8px rgba(77, 77, 77, 0.1);
}

.card .table tbody tr {
  background: #f7f7f7;
  transition: all .5s;
}

.card .table tbody tr.dx-g-bs4-cursor-pointer:nth-child(even) {
  background: #fef0f5 !important;
}

.card .table tbody tr.dx-g-bs4-cursor-pointer:nth-child(odd) {
  background: #fff !important;
}

.card .table.dx-g-bs4-table tbody tr:nth-child(even) {
  background: #fef0f5 !important;
}

.card .table.dx-g-bs4-table tbody tr:nth-child(odd) {
  background: #fff !important;
}

.card .table {
  width: calc(100% - 0px)
}

.proxy {
  position: relative;
}

.proxy textarea:focus {
  border-color: #d81b60!important
}

.proxy form .btn.btn-primary {
  background: #d81b60;
  padding-left: 20px !important;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  padding-top: 0px;
  border: none;
  font-size: 14px;
  margin-right: 0px!important;
  cursor: pointer;
  /* margin-bottom: 25px; */
  display: inline-block;
  text-transform: none;
  float: right;
}

.table-scheduler table td {
  padding-top: 5px;
  padding-bottom: 5px;
}

.s-start, .s-stop {
  padding: 0px;
  border: none;
  font-size: 20px;
  line-height: normal;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  background: transparent;
  color: #fff;
  border-radius: 15px;
  width: 85px;
  text-align: left;
  position: relative;
}

.table-scheduler table tr:last-child {
  background: transparent!important;
}

.table-scheduler td label {
  margin-bottom: 0px;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  color: #fff;
  font-weight: 600;
  width: 70px;
  font-size: 13px;
  line-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.scheduler.count {
  top: 24px;
  left: 240px;
}

.s-start span, .s-stop span {
  font-size: 30px;
  height: 20px;
  line-height: 20px;
}

.s-start {
  background: rgb(12, 150, 0);
  text-align: right;
}

.s-start::after {
  width: 14px;
  height: 14px;
  background: #fff;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
}

.s-start:hover {
  background: rgb(23, 202, 6);
}

.s-stop {
  background: rgb(150, 0, 0);
  margin-bottom: 0px;
  text-align: left;
}

.s-stop label {
  padding-left: 10px !important;
}

.s-stop::after {
  width: 14px;
  height: 14px;
  background: #fff;
  border-radius: 50%;
  content: "";
  position: absolute;
  right: 3px;
  top: 3px;
}

.s-stop:hover {
  background: rgb(224, 1, 1);
  margin-bottom: 0px;
}

.gridLoader {
  position: absolute;
  width: calc(100% - 85px);
  height: 100%;
  right: 0px;
  top: 0px;
  background: rgba(255, 255, 255, .6);
  line-height: 9;
  text-align: center;
  z-index: 9;
}

.gridLoader.last-syns {
  left: 0px!important;
  width: 100%!important;
}

.gridLoader.last-syns span {
  position: relative;
  top: 45%;
}

.gridLoader span {
  color: #666;
}

.rc-time-picker-panel-select li:hover {
  background: #d81b60;
  color: #fff;
}

.addTimer .rc-time-picker-clear {
  top: -1px;
  width: 15px;
  height: 15px;
}

.rc-time-picker-panel-narrow {
  max-width: 57px;
}

.addTimer p[title="Auto"] {
  white-space: nowrap;
}

.addTimer p[title="Auto"] span {
  display: inline-block;
}

.addTimer .rc-time-picker {
  width: 40px;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}

.last-sys input {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 30px;
  font-size: 15px;
  font-weight: bold;
  width: 55px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  top: -2px;
  position: relative;
  margin-left: 4px;
  margin-right: 4px;
}

.addTimer .rc-time-picker input {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
}

.proxy .btn.btn-primary:hover {
  background: #ea256d;
}

.proxy.count {
  top: -56px;
  left: 119px;
}

.proxy table {
  width: 100%;
  /* display: inline-block; */
  background: #fff;
}

.proxy .form-group {
  margin-bottom: 0px;
}

.proxy textarea {
  width: 100%;
  display: inline-block;
}

.proxy label b {
  font-size: 14px;
  color: #3c4858;
}

.proxy .tableList .d-flex.flex-column {
  display: inline-block!important;
}

/* .proxy table td
{
  width: 25%;
}
.proxy table td:nth-child(2)
{
  width: 25%;
  text-align: center;

}
.proxy table td:nth-child(3)
{
  width: 25%;
}
.proxy table td:nth-child(4)
{
  width: 25%;
} */

.proxy table th, .proxy table td {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.proxy table thead tr:first-child {
  background: #d81b60 !important;
  color: #fff;
}

.proxy table tbody td {
  font-size: 13px;
  font-weight: normal;
  word-break: break-all;
}

.proxy-scroll table tbody tr:nth-child(even) {
  background: #fef0f5 !important;
}

.proxySuccess {
  color: rgb(12, 150, 0);
  margin-bottom: 0px;
}

.proxySuccess span {
  font-size: 20px;
  margin-left: 10px;
}

.proxyFail {
  color: rgb(150, 0, 0);
  margin-bottom: 0px;
}

.proxyFail span {
  font-size: 20px;
  margin-left: 10px;
}

.proxy-scroll {
  width: 100%;
  overflow-y: auto;
  max-height: 400px;
}

.dashboard {
  position: relative;
}

.scheduler {
  position: relative;
}

.reload {
  position: absolute;
  right: 0px;
  top: 8px;
  z-index: 9;
  cursor: pointer;
  opacity: .6;
}

.globalBtn {
  position: absolute;
  right: 435px;
  top: 15px;
  z-index: 9;
  cursor: pointer;
  opacity: .6;
}

.globalBtn span {
  color: #000;
  font-size: 22px;
}

.globalBtn:hover {
  opacity: 1;
}

.bulkProductsDelete {
  position: absolute;
  right: 465px;
  top: 15px;
  z-index: 9;
  cursor: pointer;
  opacity: .8;
}

.bulkProductsDelete span {
  color: #000;
  font-size: 22px;
}

.bulkProductsDelete:hover {
  opacity: 1;
}

.projectIcon .diffIcon span {
  background: #d81b60;
  opacity: 1;
  padding: 2px;
  border-radius: 3px;
  width: 22px;
  height: 22px;
  font-size: 18px;
  color: #fff;
}

.diffIcon {
  position: absolute;
  right: 400px;
  top: 15px;
  z-index: 9;
  cursor: pointer;
  opacity: .6;
}

.diffIcon span {
  color: #000;
  font-size: 22px;
}

.diffIcon:hover {
  opacity: 1;
}

.scheduler .reload {
  top: -50px;
  right: 25px;
}

.scheduler .reload span {
  color: #fff!important;
}

.dashboard .reload {}

.react-datepicker-wrapper {
  width: calc(100% - 40px)!important;
  display: inline-block;
}

.react-datepicker-wrapper button.react-datepicker__close-icon {
  padding: 0px!important;
  border: none !important;
  right: 4px;
}

.react-datepicker__close-icon::after {
  background: #ccc;
}

.react-datepicker__close-icon:hover::after {
  background: #d81b60;
}

.react-datepicker-wrapper button.react-datepicker__close-icon:hover {
  background: transparent!important;
}

.pick-calender {
  border-radius: 0px 15px 15px 0px!important;
  width: 100%!important;
  border: none;
  border: 1px solid #ced4da;
}

.pick-calender[value=""]+button {
  display: none;
}

.react-datepicker .react-datepicker__navigation--previous, .react-datepicker .react-datepicker__navigation--next {
  /* position:relative; */
  padding: 5px !important;
  width: 20px!important;
  height: 20px!important;
  cursor: pointer;
}

.react-datepicker button {
  border: 1px solid #999!important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__day--selected {
  border-color: #b5114c!important;
  background-color: #d81b60
}

.react-datepicker__day--keyboard-selected:hover {
  border-color: #d81b60!important;
  background-color: #b5114c
}

.react-datepicker .react-datepicker__navigation--next::after {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: absolute;
  left: 8px;
  border-left: 5px solid#929292;
  content: '';
  display: block;
  top: 4px;
}

.react-datepicker .react-datepicker__navigation--next:hover::after {
  border-left: 5px solid#fff;
}

.react-datepicker .react-datepicker__navigation--previous:hover::after {
  border-right: 5px solid #fff;
}

.react-datepicker .react-datepicker__navigation--previous::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  top: 4px;
  border-right: 5px solid #929292;
  position: absolute;
  left: 6px;
}

.bulkHide {
  position: absolute;
  right: 415px;
  top: 13px;
  z-index: 9;
  cursor: pointer;
  opacity: .6;
}

.bulkHide:hover {
  opacity: 1;
}

.bulkHide span {
  color: #000;
}

.bulkHide .bulkeye {
  position: absolute;
  left: 2px;
  top: 2px;
  opacity: .5;
  z-index: -1;
}

.buying .reload {
  right: 368px;
  top: 15px;
}

.products .reload {
  right: 377px;
  top: 13px;
}

.proxy .reload {
  top: -5px;
}

.history .reload {
  right: 338px;
  top: 14px;
}

.reload span {
  color: #000;
  transition: all .5s;
}

.reload:hover {
  opacity: 1;
}

.reload:hover span {
  /* transform: rotate(360deg) */
}

.searchIcon {
  position: absolute;
  z-index: 999;
  right: 6px;
  top: 18px;
  cursor: pointer;
  opacity: .5
}

.searchIcon:hover {
  opacity: 1;
}

.searchIcon span {
  position: relative;
  width: 30px;
  font-size: 22px;
  padding-left: 5px;
}

.searchIcon span::before {
  position: absolute;
  content: "";
  border-left: 1px solid #ddd;
  width: 1px;
  height: 70%;
  left: 0px;
  top: 15%;
}

.py-5.dx-g-bs4-no-data-cell {
  padding: 25px!important;
  text-align: center;
}

.card .table tbody tr td {
  /* background: #fef0f5; */
  font-weight: 400;
  font-size: 13px;
  padding: .5rem!important;
  border-right: 1px solid #ccc;
}

.card .table tbody tr td:last-child {
  border-right: none;
}

.card .table tbody tr td:first-child {
  border-right: 1px solid #ccc;
}

.dx-g-bs4-table-cell p {
  white-space: normal;
  word-break:break-word;
}

.page-item.active .page-link {
  background: #d81b60;
  border-color: #d81b60;
}

.page-link {
  color: #d81b60;
}

.page-item:first-child .page-link {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding-left: 20px!important
}

.page-item:last-child .page-link {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-right: 20px!important
}

.card-header {
  padding-left: 0px;
  padding-right: 0px;
}

.card-header .btn-outline-secondary {
  border: none;
  color: #000;
  font-weight: 500
}

.card-header .btn-outline-secondary:hover {
  background: transparent;
  color: #000;
}

.dx-g-bs4-group-cell i.oi-chevron-right {
  position: relative;
  transition: all .5s;
  width: 20px;
  display: inline-block;
  cursor: pointer;
}

.dx-g-bs4-group-cell i.oi-chevron-right::after {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 10px solid #ff70a3;
  border-bottom: 10px solid transparent;
  position: absolute;
  content: "";
  top: -3px;
}

.dx-g-bs4-group-cell i.oi-chevron-bottom {
  position: relative;
  transition: all .5s;
  width: 20px;
  display: inline-block;
  cursor: pointer;
}

.dx-g-bs4-group-cell i.oi-chevron-bottom::after {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 10px solid #ff70a3;
  border-bottom: 10px solid transparent;
  position: absolute;
  content: "";
  top: 2px;
  transform: rotate(90deg);
  cursor: pointer;
}

.table.dx-g-bs4-table {
  margin-bottom: 0px!important;
}

.dx-g-bs4-header-cell span.dx-g-bs4-cursor-pointer {
  cursor: pointer;
  width: calc(100% + 15px);
  left: -7px;
  position: relative;
  height: 25px;
  padding-left: 7px;
  padding-right: 7px;
  margin-left: 7px;
  top: 2px;
}

.dx-g-bs4-header-cell span.dx-g-bs4-cursor-pointer:hover {
  background: #b5114c;
}

.dx-rg-bs4-table-header-title {
  color: #fff!important;
  cursor: pointer;
}

.oi-arrow-thick-top {
  position: relative;
}

.oi-arrow-thick-top::after {
  position: absolute;
  content: "";
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  width: 0px;
  height: 0px;
  top: -3px;
  cursor: pointer;
}

.oi-arrow-thick-bottom {
  position: relative;
}

.oi-arrow-thick-bottom::after {
  position: absolute;
  content: "";
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  width: 0px;
  height: 0px;
  top: -2px;
  cursor: pointer;
  transform: rotate(180deg);
}

button[title="Logout"]:hover {
  opacity: .6;
}

.card-header button span.oi-eye {
  background-image: url("../img/eye.svg");
  width: 25px;
  height: 25px;
  display: inline-block;
  background-repeat: no-repeat;
  position: relative;
  z-index: 999;
  opacity: .6;
}

.card-header button span.oi-eye:hover:after {
  position: absolute;
  width: auto;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 1);
  right: 0px;
  left: auto;
  top: 25px;
  font-size: 12px;
  color: #fff;
  border-radius: 3px;
  box-shadow: 3px 2px 6px -2px #000;
  z-index: 999;
  content: "Column Visibility";
  white-space: nowrap;
}

.card-header button span.oi-eye:hover {
  opacity: 1;
}

.card-header .btn-group .oi-arrow-thick-top:after {
  border-bottom: 5px solid #000;
  top: 5px;
}

.card-header .btn-group .oi-arrow-thick-bottom:after {
  border-bottom: 5px solid #000;
  top: 7px;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle {
  border: none;
  background: transparent;
  box-shadow: none !important;
}

.popover button {
  padding-left: 10px!important;
  padding-right: 10px!important;
}

.popover button input {
  margin-right: 3px;
  position: relative;
  opacity: 1;
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  /* top: 7px; */
  display: inline-block;
  vertical-align: text-top;
  cursor: pointer;
}

.popover button input:after {
  position: absolute;
  content: '';
  background: #d81b60;
  width: 15px;
  height: 15px;
  display: block;
  cursor: pointer;
  top: 2px;
}

.popover button input:checked:before {
  position: absolute;
  content: '';
  display: block;
  width: 5px;
  height: 8px;
  border: solid rgb(255, 255, 255);
  border-width: 0px 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 9;
  left: 5px;
  top: 5px;
  cursor: pointer;
}

.dx-g-bs4-group-cell .dx-g-bs4-fixed-group-cell {
  width: 100%;
}

.dx-g-bs4-group-cell .dx-g-bs4-fixed-group-cell img {
  height: 60px;
  border-radius: 7px;
  position: relative;
  z-index: 99;
  border: none;
  display: inline-block;
}

.dx-g-bs4-group-cell .dx-g-bs4-fixed-group-cell a {
  border: 1px solid #ddd;
  border-radius: 7px;
  display: inline-block;
  background: #f7f7f7;
  position: relative;
  width: 100%;
  max-width: 86px;
  text-align: center;
}

.goatpro {
  position: relative;
}

.goatpro .popover-inner button:first-child {
  display: none;
}

.goatpro .reload {
  right: 337px;
  top: 13px;
  cursor: pointer;
}

.goatpro .dx-g-bs4-table-cell span.proImage {
  border: 1px solid #ddd;
  border-radius: 7px;
  display: inline-block;
  background: #f7f7f7;
  position: relative;
  width: 100%;
  max-width: 86px;
  text-align: center;
}

.goatpro .dx-g-bs4-group-cell .dx-g-bs4-fixed-group-cell {
  width: 100%;
}

.goatpro .dx-g-bs4-group-cell .dx-g-bs4-fixed-group-cell img {
  width: 100%;
  border-radius: 7px;
  position: relative;
  z-index: 99;
  border: none;
  display: inline-block;
  object-fit: cover;
  background: #fff;
}

.dx-g-bs4-group-cell .dx-g-bs4-fixed-group-cell a:hover::before {
  -webkit-box-shadow: inset 0 0 5px #ccc;
  box-shadow: inset 0 0 5px #ccc;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  content: "";
  border-radius: 7px;
}

.dx-g-bs4-group-cell .dx-g-bs4-fixed-group-cell a::after {
  position: absolute;
  content: "No Image";
  top: 0px;
  left: 0px;
  text-align: center;
  font-size: 13px;
  height: 100%;
  width: 100%;
  line-height: 60px;
  text-transform: uppercase;
  color: #a9a9a9;
  z-index: 9;
}

.dx-g-bs4-group-cell .dx-g-bs4-fixed-group-cell img[src="unknown"], .dx-g-bs4-group-cell .dx-g-bs4-fixed-group-cell img[src=""] {
  width: 83px;
  opacity: 0;
  display: inline-block;
}

.dx-g-bs4-group-cell .dx-g-bs4-fixed-group-cell span span {
  padding-left: 10px;
}

.dx-g-bs4-group-cell .dx-g-bs4-fixed-group-cell strong {
  display: none;
}

.btn.btn-link.dx-g-bs4-table-edit-command-cell {
  background: #fff;
  border: 1px solid #ccc;
  border-right: 1px solid #ccc !important;
  border-right: 3px;
  width: 30px;
  height: 30px;
  margin-right: 0px;
  padding: 0px!important;
  /* margin-top: 20%; */
}

.card .table tbody tr td {
  vertical-align: middle;
  white-space: normal!important;
}

.btn.btn-link.dx-g-bs4-table-edit-command-cell#commit, .btn.btn-link.dx-g-bs4-table-edit-command-cell#cancel {
  /* margin-top: 5px!important; */
}

.btn.btn-link.dx-g-bs4-table-edit-command-cell svg {
  width: 20px;
}

.btn.btn-link.dx-g-bs4-table-edit-command-cell svg {
  fill: #000;
}

.card div[role="alertdialog"] {
  max-width: 100%!important;
  margin-bottom: 0px!important
}

#edit, #delete, #commit, #cancel {
  position: relative;
}

#edit:hover, #delete:hover, #commit:hover, #cancel:hover {
  position: relative;
  border-color: #d81b60;
  border-right: 1px solid #d81b60 !important;
  background-color: #d81b60;
}

#edit:hover svg, #delete:hover svg, #commit:hover svg, #cancel:hover svg {
  fill: #fff;
}

#edit:hover::after, #delete:hover::after, #commit:hover::after, #cancel:hover::after {
  position: absolute;
  width: auto;
  padding: 5px 10px;
  background: rgba(0, 0, 0, .8);
  font-size: 10px;
  color: #fff;
  border-radius: 3px;
  box-shadow: 3px 2px 6px -2px #000;
  z-index: 99;
  left: 35px;
  top: 15px;
}

#edit:hover::after {
  content: "Edit";
}

#delete:hover::after {
  content: "Delete"
}

#commit:hover::after {
  content: "Save"
}

#cancel:hover::after {
  content: "Cancel"
}

.btn-group .btn-outline-secondary {
  display: none;
}

.dx-g-bs4-table .btn-outline-secondary svg {
  width: 20px;
  fill: #999;
}

.dx-g-bs4-table .input-group-prepend .btn-outline-secondary:disabled {
  display: none;
}

.dx-g-bs4-table .input-group .form-control[readonly] {
  display: none;
}

.div:parent .btn-outline-secondary:disabled {
  display: none;
}

.dropdown-item.active, .dropdown-item:active {
  background: #dcdcdc;
  color: #d81a60;
}

button.dropdown-item svg {
  width: 15px;
  margin-right: 10px;
}

.dropdown-item.active svg {
  fill: #fff;
}

.Dashboard-content-3, .Dashboard-content-329 {
  margin-top: 50px!important;
}

.sweet-alert .btn.btn-lg.btn-primary {
  background: #d81b60;
  padding-left: 20px !important;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  padding-top: 0px;
  border: none;
  font-size: 14px;
  margin-right: 0px!important;
}

.sweet-alert .btn.btn-link {
  border: 1px solid #999;
  color: #000;
  text-decoration: none;
  height: 30px;
  padding-top: 0px;
  line-height: 30px;
  font-size: 14px;
}

.sweet-alert p {
  margin-bottom: 5px!important;
  margin-top: 5px!important;
}

.dx-g-bs4-table-sticky {
  position: sticky;
  width: 100% !important;
  min-width: 100%!important;
  table-layout: fixed;
  overflow: visible;
  top: 0;
  z-index: 99;
  margin-bottom: 0px;
}

.dx-g-bs4-table-sticky tr:first-child {
  background: #d81b60 !important;
}

.table-responsive.dx-g-bs4-table-container {
  height: auto!important;
  min-height: 400px;
}

.proxy .table-responsive.dx-g-bs4-table-container {
  height: 430px!important
}

.table-responsive table tr:first-child {
  border-bottom: 1px solid #fff !important;
}

.table-responsive table tr:last-child {
  background: #f7f7f7;
}

.table-responsive table:last-child tr:last-child {
  border-bottom: 1px solid #ccc !important;
}

.input-group input {
  padding: 0px 10px 0px 5px !important;
  font-size: 12px!important;
  height: 25px!important;
  border-radius: 15px;
}

.input-group button {
  padding: 0px 5px 0px 10px !important;
  border: 1px solid #ced4da!important;
  border-radius: 15px;
}

.material-icons.icon-image-preview {
  width: 30px;
}

.input-group button:hover {
  background: #d81b60;
}

.input-group button:hover svg {
  fill: #fff;
}

.dx-g-bs4-fixed-header .input-group input[type="checkbox"], #gridSweetId input[type="radio"], .proxy table th input[type="radio"] {
  position: relative;
  width: 20px!important;
  height: 20px!important;
  margin-right: 8px;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.proxy table th input[type="radio"] {
  display: inline-block!important;
}

.dx-g-bs4-fixed-header .input-group input[type="checkbox"]+label, #gridSweetId input[type="radio"]+label, .proxy table th input[type="radio"]+label {
  font-size: 12px;
  margin-right: 10px;
  top: -6px;
  position: relative;
  margin-bottom: 0px;
  left: 9px;
  cursor: pointer;
}

.dx-g-bs4-fixed-header .input-group input[type="checkbox"]:after, #gridSweetId input[type="radio"]:after, .proxy table th input[type="radio"]:after {
  background: #fff;
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid #ccc;
  top: -1px;
}

.dx-g-bs4-fixed-header .input-group input[type="checkbox"]:hover::after, #gridSweetId input[type="radio"]:hover:after, .proxy table th input[type="radio"]:hover::after {
  border: 2px solid #d81b60;
}

.cu-checkbox {
  min-width: 150px;
  top: 2px;
  position: relative;
}

.cu-checkbox input {
  display: inline-block!important;
}

.dx-g-bs4-fixed-header .input-group input[type="checkbox"]:checked::after, #gridSweetId input[type="radio"]:checked::after, .proxy table th input[type="radio"]:checked::after {
  background: #d81b60!important;
}

.dx-g-bs4-fixed-header .input-group input[type="checkbox"]:checked::before, #gridSweetId input[type="radio"]:checked::before, .proxy table th input[type="radio"]:checked::before {
  position: absolute;
  content: "";
  height: 6px;
  width: 9px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  z-index: 9;
  top: 5px;
  left: 11px;
  transform: rotate(-45deg);
  cursor: pointer;
}

#gridSweetId input[type="radio"]:checked::before {
  left: 6px;
}

.BulkUpdate .bulk.count {
  left: 165px;
}

.goatstock .goatstok.count {
  left: 195px;
}

.BulkUpdate .reload {
  right: 80px;
  top: 93px;
}

.goatstock .reload {
  right: 80px;
  top: 95px;
}

.BulkUpdate .table-responsive td:nth-child(3) {
  text-align: center;
}

.BulkUpdate .table-responsive td:nth-child(4) {
  text-align: center;
}

.globalUpdate .table-responsive td:nth-child(2) {
  text-align: center;
}

.globalUpdate .table-responsive td:nth-child(3) {
  text-align: center;
}

.globalUpdate .table-responsive td:nth-child(4) {
  text-align: center;
}

.globalUpdate .table-responsive td:nth-child(5) {
  text-align: center;
}

.globalUpdate .table-responsive td:nth-child(6) {
  text-align: center;
}

.globalUpdate .table-responsive td:nth-child(7) {
  text-align: center;
}
.globalUpdate .table-responsive td:nth-child(8) {
  text-align: center;
}


.BulkUpdate .table-responsive td:nth-child(5) {
  text-align: center;
}

.BulkUpdate .table-responsive td:nth-child(6) {
  text-align: center;
}

.BulkUpdate .table-responsive td:nth-child(7) {
  text-align: center;
}

.BulkUpdate .table-responsive td:nth-child(8) {
  text-align: center;
}


.BulkUpdate .table-responsive th:nth-child(3) div {
  text-align: center;
  display: block!important;
}

.globalUpdate .table-responsive th:nth-child(3) div {
  text-align: center;
  display: block!important;
}

.globalUpdate .table-responsive th:nth-child(4) div {
  text-align: center;
  display: block!important;
}
.globalUpdate .table-responsive th:nth-child(5) div {
  text-align: center;
  display: block!important;
}

.globalUpdate .table-responsive th:nth-child(6) div {
  text-align: center;
  display: block!important;
}

.BulkUpdate .table-responsive th:nth-child(2) div {
  text-align: center;
  display: block!important;
}

.BulkUpdate .table-responsive th:nth-child(5) div {
  text-align: center;
  display: block!important;
}

.BulkUpdate .table-responsive th:nth-child(6) div {
  text-align: center;
  display: block!important;
}

.BulkUpdate .table-responsive th:nth-child(7) div {
  text-align: center;
  display: block!important;
}

.BulkUpdate .table-responsive th:nth-child(8) div {
  text-align: center;
  display: block!important;
}

.BulkUpdate .table-responsive th:nth-child(4) div {
  text-align: center;
  display: block!important;
}

.bulkPopup.sweet-alert .text-muted.lead {
  max-height: 100%!important;
}

.sweet-alert .text-muted.lead td
{
  font-size: 14px;
  /* position: relative; */
}
.sweet-alert h5
{
  font-size: 18px;
  /* position: relative; */
}

.sweet-alert td
{
  font-size: 14px;
  /* position: relative; */
}

.goatstock .dx-g-bs4-table-cell .popImages {
  border: 1px solid #ddd;
  border-radius: 7px;
  display: block;
  background: #f7f7f7;
  position: relative;
  width: 100%;
  max-width: 86px;
  text-align: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.goatstock .dx-g-bs4-table-cell .popImages:hover:before {
  box-shadow: inset 0 0 5px #ccc;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  content: "";
  border-radius: 7px;
}

.goatstock .dx-g-bs4-table-cell .popImages div {
  display: none;
}

.goatstock .dx-g-bs4-table-cell .popImages:hover div {
  display: block;
  position: absolute;
  top: 55px;
  z-index: 999;
  left: -10px;
  width: 100%;
  height: 100%;
}

.goatstock .dx-g-bs4-table-cell .popImages a {
  position: absolute;
  left: -20px;
  width: 60px;
  height: 45px;
  background: rgba(0, 0, 0, .9);
  padding: 3px;
  border-radius: 5px;
  transition: padding .2s;
}

.goatstock .dx-g-bs4-table-cell .popImages a:hover {
  padding: 4px;
}

.goatstock .dx-g-bs4-table-cell .popImages a::before {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 0px solid transparent;
  content: "";
  border-top: 10px solid rgba(0, 0, 0, .9);
  position: absolute;
  top: -5px;
  transform: rotate(-135deg);
  right: -5px;
}

.goatstock .dx-g-bs4-table-cell .popImages a:last-child::before {
  left: -5px;
  transform: rotate(45deg);
}

.goatstock .dx-g-bs4-table-cell .popImages a:first-child {
  left: -10px!important;
}

.goatstock .dx-g-bs4-table-cell .popImages a:last-child {
  left: 55px!important;
}

.goatstock .dx-g-bs4-table-cell .popImages a img {
  width: 100%;
  object-fit: cover;
  height: 100%!important;
  border-radius: 5px;
}

.goatstock .dx-g-bs4-table-cell img {
  width: 100%;
  border-radius: 7px;
  position: relative;
  z-index: 99;
  border: none;
  display: inline-block;
  object-fit: cover;
  background: #fff;
  height: 60px;
}

.buying .allBids {
  right: 620px;
}

.products .allBids {
  right: 580px;
}

.buying .bulkUpdate {
  right: 500px;
}

.products .bulkUpdate {
  right: 455px;
}

#gridSweetId h6 {
  color: #ff0000;
  text-transform: none;
  font-size: 15px;
  font-weight: normal;
  display: none;
  margin-bottom: 0px;
}

#gridSweetId input[type="number"] {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 25px;
}

#gridSweetId h6.h6ClassToShow {
  display: block!important;
}

#gridSweetId table {
  margin-bottom: 30px;
  margin-top: 30px;
}

#gridSweetId table tr td div {
  padding-top: 0px!important;
  margin-top: -4px!important;
}

#gridSweetId table tr td:first-child {
  text-align: right;
  padding-right: 15px;
  font-weight: bold;
  padding-bottom: 10px;
}

#gridSweetId table tr td:last-child {
  text-align: left;
  padding-bottom: 10px;
}

.allBids {
  background: #d81b60!important;
  border-radius: 30px;
  color: #fff;
  position: absolute;
  border: none;
  width: 150px;
  z-index: 99;
  top: 9px;
  height: 30px;
  cursor: pointer;
  /* border: 10px solid #eee; */
}

.bulkUpdate {
  background: #d81b60!important;
  border-radius: 30px;
  color: #fff;
  position: absolute;
  border: none;
  width: 110px;
  z-index: 99;
  top: 9px;
  height: 30px;
  cursor: pointer;
  /* border: 10px solid #eee; */
}

.bulkUpdate:hover, .allBids:hover {
  background: #ff2172!important
}

.bulkUpdate:disabled {
  opacity: .5;
}

.allBids:disabled {
  opacity: .5;
}

div[role="alertdialog"] {
  width: 100%;
  margin-bottom: 5px;
  max-width: 100%;
}

.spinner {
  width: 300px;
  display: inline-block;
  position: absolute;
  /* left: 160px; */
  z-index: 99;
  top: 15px;
}

.spinner img {
  height: 30px;
}

.spinner-box {
  width: 100%;
  display: inline-block;
  position: relative;
  /* left: 160px; */
  z-index: 99;
  top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  height: 100%;
  line-height: 100%;
}

.spinner-box span {
  position: relative;
  width: 100%;
  left: 0px;
  top: calc(50% - 30px);
  font-weight: bold;
}

.spinner-box img {
  height: 60px;
}

.sweet-alert h2 {
  position: absolute!important;
  font-size: 20px!important;
  left: 0px;
  top: 30px;
  width: 100%;
  border: none;
  margin-left: 0px;
}

.sweet-alert .spinner-box {
  position: relative;
  z-index: 99;
  background-color: rgba(255, 255, 255, .95);
  left: 0px;
}

th span.dx-g-bs4-user-select-none.dx-g-bs4-cursor-pointer .oi-arrow-thick-top::after {
  opacity: 1!important;
}

th span.dx-g-bs4-user-select-none .oi-arrow-thick-top::after {
  opacity: 0;
}

th span.dx-g-bs4-user-select-none .dx-rg-bs4-table-header-title {
  cursor: text;
}

th span.dx-g-bs4-user-select-none.dx-g-bs4-cursor-pointer .dx-rg-bs4-table-header-title {
  cursor: pointer;
}

.sweet-alert .text-muted.lead {
  max-height: 400px;
  overflow-y: auto;
  min-height: 45px;
  font-size: 100%;
  /* position: relative; */
}

.sweet-alert>div:first-of-type {
  margin-top: 50px!important;
}

.sweet-alert .text-muted.lead div[role="alertdialog"] {
  padding: 6px 10px!important;
  font-size: 12px!important;
  border-bottom: 1px solid #ccc;
  text-align: left;
  color: #000;
  background-color: transparent!important;
  border-bottom: 1px solid #ccc;
  display: inline-block;
  min-height: 45px;
}

.sweet-alert div[role="alertdialog"] {
  padding: 6px 10px!important;
  font-size: 12px!important;
  border-bottom: 1px solid #ccc;
  text-align: left;
  color: #000;
  background-color: transparent!important;
  border-bottom: 1px solid #ccc;
  display: inline-block;
  min-height: 45px;
}

.sweet-alert .text-muted.lead div[role="alertdialog"]+svg+button {
  position: absolute;
  z-index: 9;
  /* bottom: 0px; */
  right: 0px;
  margin-top: 8px;
  background: #fff;
  border: none;
  border-radius: 10px;
  font-size: 11px;
  padding: 0px 10px;
  height: 23px;
  line-height: 15px;
  background: #ffffff;
  border: 1px solid #d81b60;
  color: #d81b60;
  font-weight: bold;
}

.sweet-alert div[role="alertdialog"]+svg+button {
  position: absolute;
  z-index: 9;
  /* bottom: 0px; */
  right: 0px;
  margin-top: 8px;
  background: #fff;
  border: none;
  border-radius: 10px;
  font-size: 11px;
  padding: 0px 10px;
  height: 23px;
  line-height: 15px;
  background: #ffffff;
  border: 1px solid #d81b60;
  color: #d81b60;
  font-weight: bold;
}

.sweet-alert .text-muted.lead div[role="alertdialog"]+svg+button:hover {
  background-color: #d81b60;
  border: 1px solid #d81b60;
  color: #fff;
}

.sweet-alert  div[role="alertdialog"]+svg+button:hover {
  background-color: #d81b60;
  border: 1px solid #d81b60;
  color: #fff;
}

.sweet-alert {
  max-width: 550px!important;
  z-index: 99999 !important;
  width: 100%!important;
}

.sweet-alert .text-muted.lead div[role="alertdialog"]+svg {
  position: absolute;
  left: 0px;
  margin-top: 4px;
  fill: #d81b60;
}

.sweet-alert div[role="alertdialog"]+svg {
  position: absolute;
  left: 0px;
  margin-top: 4px;
  fill: #d81b60;
}

.sweet-alert .text-muted.lead div[role="alertdialog"]+svg.tick-green {
  fill: #47c300!important;
}

.sweet-alert div[role="alertdialog"]+svg.tick-green {
  fill: #47c300!important;
}

.sweet-alert .text-muted.lead div[role="alertdialog"] div div {
  width: calc(100% - 45px);
  line-height: 16px;
  margin-left: 20px;
}

.sweet-alert div[role="alertdialog"] div div {
  width: calc(100% - 45px);
  line-height: 16px;
  margin-left: 20px;
}

.sweet-alert .text-muted.lead div[class*="SnackbarContent-success"] div div {
  width: 100%;
}

.sweet-alert div[class*="SnackbarContent-success"] div div {
  width: 100%;
}

.sweet-alert .text-muted.lead div[class*="SnackbarContent-success"]+img {
  position: absolute;
  left: 0px;
  margin-top: 2px;
}

.sweet-alert div[class*="SnackbarContent-success"]+img {
  position: absolute;
  left: 0px;
  margin-top: 2px;
}

.export {
  position: absolute;
  z-index: 99;
  right: 340px;
  top: 16px;
  transform: rotate(270deg);
}

.export img {
  height: 19px;
  opacity: .6;
}

.export img:hover {
  opacity: 1;
}

.card-header .form-control.w-25 {
  max-width: 290px !important;
  width: 290px!important;
  min-width: 290px;
  font-size: 13px;
  padding-right: 45px;
}

.sa-show, .sa-hide {
  position: relative;
}

.sa-hide:after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, .6);
  left: 0px;
  top: 0px;
  z-index: 9;
}

.cancelBtn {
  padding-top: 10px;
  min-height: 40px;
}

.cancelBtn button {
  border: 1px solid #d81b60;
  border-radius: 5px;
  background-color: #d81b60;
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  cursor: pointer;
}

.cancelBtn button:hover {
  background-color: #e81d67;
  border: 1px solid #d81b60;
  color: #fff;
}

.sweet-alert div[style*="width:80px"] {
  margin-top: 50px;
}

.errorOutlineIcon {
  width: 50px !important;
  height: 50px!important;
  margin-top: 0px;
  margin-bottom: 15px;
}

.errorOutlineIcon path:last-child {
  fill: #d81b60;
}

.dx-g-bs4-table-edit-cell input {
  height: 28px;
  padding-left: 10px;
  padding-right: 5px;
  border-radius: 13px;
}

.dx-g-bs4-table-edit-cell input:focus {
  border-color: #d81b60;
}

.dx-g-bs4-table-edit-cell input .Dashboard-content-538, .jss3 {
  margin-top: 50px!important
}

.dx-g-bs4-table-edit-cell p {
  margin: 0px;
}

.autobided {
  font-size: 17px;
  position: absolute;
  right: 0px;
  bottom: -18px;
  color: #3C4858;
}

.percentaged {
  font-size: 17px;
  position: absolute;
  right: 0px;
  bottom: -18px;
  color: #3C4858;
}

.table-sales tr:last-child {
  background: transparent!important;
}

.table-sales td {
  vertical-align: middle;
}

.table-sales .material-icons {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  box-shadow: 0px 12px 20px -10px rgba(255, 255, 255, 0.28), 0px 4px 20px 0px rgba(0, 0, 0, 0.12), 0px 7px 8px -5px rgba(255, 255, 255, 0.2) !important;
  padding: 5px;
  margin-right: 10px;
}

.table-sales .updateTime {
  display: inline-block;
}

.table-sales .updateTime span {
  position: relative;
  box-shadow: none !important;
  margin-right: 5px!important;
  padding: 0px;
}

.table-sales .updateTime span.material-icons {
  font-size: 18px;
}

.autobided span {
  font-size: 16px!important;
  width: 20px!important;
  height: 20px!important;
  line-height: 20px!important;
  color: #ffc107!important;
  display: inline-block;
  vertical-align: bottom;
}

.percentaged span {
  font-size: 16px!important;
  width: 20px!important;
  height: 20px!important;
  line-height: 20px!important;
  display: inline-block;
  vertical-align: bottom;
}

.upPercent {
  color: green!important;
}

.downPercent {
  color: red!important;
}

.PercentageMaxBid span {
  display: inline-block;
  font-size: 15px;
}

.table-sales table {
  width: 100%;
}

.runDiv {
  position: absolute;
  top: 12px;
  right: 88px;
  z-index: 9;
}

.runDiv select {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 15px;
}

.card-title.last-sys {
  padding-left: 100px;
  text-align: left;
  padding-top: 15px;
  color: #000;
}

.dx-g-bs4-table-edit-cell input[type="checkbox"] {
  position: relative;
  width: 20px!important;
  height: 20px!important;
  margin-right: 8px;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.dx-g-bs4-table-edit-cell input[type="checkbox"]:after {
  background: #fff;
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid #ccc;
  top: -1px;
  left: 5px;
}

.dx-g-bs4-table-edit-cell input[type="checkbox"]:hover:after {
  border: 2px solid #d81b60;
}

.dx-g-bs4-table-edit-cell input[type="checkbox"]:checked::before {
  position: absolute;
  content: "";
  height: 6px;
  width: 9px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  z-index: 9;
  top: 5px;
  left: 11px;
  transform: rotate(-45deg);
  cursor: pointer;
}

.dx-g-bs4-table-edit-cell input[type="checkbox"]:checked::after {
  background-color: #d81b60!important;
}

.dx-g-bs4-table-cell input[type="checkbox"] {
  position: relative;
  width: 20px!important;
  height: 20px!important;
  cursor: pointer;
  margin-right: 8px;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  opacity: .5;
}

.dx-g-bs4-table-cell input[type="checkbox"]:after {
  background: #fff;
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid #ccc;
  top: -1px;
  left: 5px;
}

.dx-g-bs4-table-cell input[type="checkbox"]:checked::before {
  position: absolute;
  content: "";
  height: 6px;
  width: 9px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  z-index: 9;
  top: 5px;
  left: 11px;
  transform: rotate(-45deg);
  cursor: pointer;
}

.dx-g-bs4-table-cell input[type="checkbox"]:checked::after {
  background-color: #d81b60!important;
}

.products .clipboard {
  margin-left: 15px;
  display: inline-block;
  position: absolute;
  cursor: pointer;
  width: 30px !important;
  height: 24px;
  font-size: 18px !important;
  top: 17px;
  opacity: 0;
  transition: .25s opacity;
  -webkit-transition: .25s opacity;
}

.dashboard h5 {
  width: calc(100% - 105px);
  display: inline-block;
  vertical-align: top;
  text-transform: none;
  line-height: 1.2;
  border: none;
}

.dashboard h5 span {
  font-size: 13px;
  white-space: nowrap;
  float: right;
}

.dashboard h5 span i {
  font-style: normal;
  color: #999999;
  width: 100%;
}

.dx-g-bs4-cursor-pointer:hover .clipboard {
  display: inline-block;
  opacity: .5;
}

.clipboard {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  width: 30px !important;
  height: 24px;
  font-size: 18px !important;
  top: 17px;
  opacity: 0;
  transition: .25s opacity;
  margin-left: 64px;
  -webkit-transition: .25s opacity;
}

.clipboard svg {
  width: 18px;
  height: 18px;
}

.clipboard span {
  padding: 5px;
  background-color: #eee;
  font-size: 11px;
  border-radius: 15px;
  width: 60px;
  display: inline-block;
}

.dx-g-bs4-group-cell .rowdel {
  opacity: 0;
  cursor: pointer;
  transition: .25s opacity;
  display: inline-block;
  position: absolute;
  top: 18px;
  margin-left: 35px;
  width: 20px;
}

.dx-g-bs4-group-cell:hover .rowdel {
  opacity: .5;
  display: inline-block;
  margin-left: 35px;
}

.dx-g-bs4-group-cell:hover .rowdel:hover {
  opacity: 1;
}

.dx-g-bs4-cursor-pointer:hover .synsBtn {
  display: inline-block;
  opacity: .5;
}

.synsBtn {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  width: 30px !important;
  height: 24px;
  font-size: 18px !important;
  top: 23px;
  opacity: 0;
  /* margin-left: 20px; */
  transition: .25s opacity;
  transform: rotate(-40deg);
  -webkit-transition: .25s opacity;
  -webkit-transform: rotate(-40deg);
}

.dx-g-bs4-cursor-pointer:hover .synsBtn:hover {
  opacity: 1;
  display: inline-block;
}

.dx-g-bs4-header-cell:hover {
  background: transparent;
  position: static!important;
}

.dx-g-bs4-group-cell:hover .rowdel::after {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 3px;
  position: absolute;
  content: "";
}

.dx-g-bs4-header-cell:hover .oi-arrow-thick-top {
  visibility: visible !important;
  opacity: .6;
}

.sweet-alert .btn.btn-lg.btn-primary:hover {
  background: #ea256d;
}

.sweet-alert .btn.btn-link:hover {
  background: #f1f1f1;
}

.allBids:hover {
  background: #e81d67!important;
}

.errorOutlineIcon.completed path:last-child {
  fill: #37dc07;
}

.errorOutlineIcon.completed {
  border: 5px solid #37dc07;
  border-radius: 50%;
  margin-bottom: 20px;
  margin-top: 10px;
}

#loginPassword+div {
  cursor: pointer;
}

.card-header .form-control.w-25:focus {
  border-color: #E91E63;
}

.input-group:focus button, .input-group:focus-within button {
  border-color: #d81b60 !important;
}

.input-group:focus-within input:focus {
  border-color: #d81b60 !important;
}

img[title="Active"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img[title="Inactive"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.track svg {
  height: 15px;
  width: 15px;
  fill: #d81b60;
}

.track {
  color: #d81b60;
}

.track:hover {
  text-decoration: underline;
  color: #d81b60;
}

.track:visited {
  text-decoration: none;
  color: #d81b60;
}

.pending .table:last-child td {
  white-space: normal!important;
}

.products .dx-g-bs4-table tr td:nth-child(2), .products .dx-g-bs4-table tr th:nth-child(2) {
  /* display:none; */
}

.visibleBtn {
  opacity: 0;
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 20px;
  transition: .25s opacity;
  margin-left: 30px;
}

.dx-g-bs4-cursor-pointer:hover .visibleBtn {
  opacity: .5;
}

.visibleBtn:hover {
  opacity: 1!important;
}

.all-visibleBtn {
  position: absolute;
  right: 340px;
  z-index: 999;
  top: 14px;
  opacity: .6;
  cursor: pointer;
  color: #000;
}

.all-visibleBtn:hover {
  opacity: 1
}

.sweet-alert {
  top: 35%!important;
}

.bidHistoryCScroll {
  max-height: 200px;
  overflow-y: auto;
  display: inline-block;
  width: 100%;
  position: relative;
}

.bidHistoryCScroll table td {
  width: 20%;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 12px;
  font-weight: 400;
}

.bidHistoryCScroll table td:last-child {
  width: 30%;
}

.bidHistoryCScroll table tr:first-child {
  background: #00d6964d;
}

.bidhishead tr:first-child {
  background: transparent !important;
}

.bidhishead {
  margin-bottom: 0px;
}

.bidhishead td {
  width: 20%;
  font-weight: bold !important;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 12px;
  /* padding-right: 15px; */
}

.bidhishead td:last-child {
  width: 30%;
}

.maxBidHistory {
  position: absolute;
  left: 20px;
  opacity: 1;
  cursor: pointer;
  background: #fff;
  border: 1px solid #ccc;
  border-right: 1px solid #ccc !important;
  border-right: 3px;
  width: 30px;
  height: 30px;
  margin-right: 0px;
  padding: 0px!important;
  border-radius: .25rem;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  margin-top: -5px;
}

.bidHistory {
  position: absolute;
  left: 65px;
  opacity: 1;
  cursor: pointer;
  background: #fff;
  border: 1px solid #ccc;
  border-right: 1px solid #ccc !important;
  border-right: 3px;
  width: 30px;
  height: 30px;
  margin-right: 0px;
  padding: 0px!important;
  border-radius: .25rem;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  margin-top: -5px;
}

.bidHistory:hover, .maxBidHistory:hover {
  background: #d81b60;
}

.bidHistory:hover .material-icons, .maxBidHistory:hover .material-icons {
  color: #fff;
}

.bidHistory .material-icons, .maxBidHistory .material-icons {
  font-size: 18px;
  line-height: 28px;
  height: 30px;
  display: inline-block;
  color: #000;
  font-weight: bold;
}

.btn.btn-outline-secondary .d-block.oi.oi-calendar {
  background-image: url("../img/calender.svg");
  width: 15px;
  height: 15px;
  background-size: 15px;
  opacity: .5;
  display: inline-block;
}

.btn.btn-outline-secondary:hover .d-block.oi.oi-calendar {
  background-image: url("../img/calender-w.svg");
  width: 15px;
  height: 15px;
  background-size: 15px;
  opacity: 1;
  display: inline-block;
}

.btn.btn-outline-secondary .d-block.oi.oi-magnifying-glass {
  background-image: url("../img/in.svg");
  width: 15px;
  height: 15px;
  background-size: 15px;
  opacity: .5;
  display: inline-block;
  position: relative;
}

.btn.btn-outline-secondary:hover .d-block.oi.oi-magnifying-glass {
  background-image: url("../img/in-w.svg");
  width: 15px;
  height: 15px;
  background-size: 15px;
  opacity: 1;
  display: inline-block;
  position: relative;
}

.btn.btn-outline-secondary:hover .d-block.oi.oi-magnifying-glass.cross:after {
  border-color: #fff;
}

.dropdown-item .d-block.oi.oi-calendar {
  background-image: url("../img/calender.svg");
  width: 15px;
  height: 15px;
  background-size: 15px;
  opacity: .5;
  display: inline-block;
  margin-right: 10px;
}

.dropdown-item.active .d-block.oi.oi-calendar {
  background-image: url("../img/in-w.svg");
  width: 15px;
  height: 15px;
  background-size: 15px;
  opacity: 1;
  display: inline-block;
}

.dropdown-item .d-block.oi.oi-magnifying-glass {
  background-image: url("../img/in.svg");
  width: 15px;
  height: 15px;
  background-size: 15px;
  opacity: .5;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.btn.btn-outline-secondary .d-block.oi.oi-magnifying-glass.cross:after, .popover-inner .dropdown-item:last-child .d-block.oi.oi-magnifying-glass.cross:after {
  position: absolute;
  width: 100%;
  content: "";
  height: 1px;
  border: 1px solid #000;
  left: 0px;
  top: 6px;
  transform: rotate(45deg);
}

/* .dropdown-item.active .d-block.oi.oi-magnifying-glass
{
  background-image: url("../img/calender-w.svg");
  width: 15px;
    height: 15px;
    background-size: 15px;
    opacity: 1;
    display:inline-block;
} */

.dx-g-bs4-table-cell a {
  color: #d81b60;
}

.productImage {
  width: 86px;
  height: 60px;
  border: 1px solid #ddd;
  border-radius: 7px;
  /* display:inline-block; */
  overflow: hidden;
  float: left;
  margin-right: 10px;
  position: relative;
}

.productImage::after {
  position: absolute;
  content: "No Image" !important;
  top: 0px;
  left: 0px;
  text-align: center;
  font-size: 13px;
  height: 100%;
  width: 100%;
  line-height: 60px;
  text-transform: uppercase;
  color: #a9a9a9;
  z-index: 9;
}

.productImage:hover:before {
  box-shadow: inset 0 0 5px #ccc;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  content: "";
  border-radius: 7px;
}

.productImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  z-index: 99;
}

.productImage img[src=""] {
  opacity: 0;
}

p.productDesc {
  /* display: inline-block; */
  width: 100%;
  margin-bottom: 0px;
  float: left;
}

p.productDesc span {
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
}

.dx-g-bs4-table-cell a:hover {
  color: #d81b60;
  text-decoration: underline;
}

.buying .popover-inner div button:nth-child(2) {
  display: none;
}

.products .popover-inner div button:nth-child(2) {
  display: none;
}

.pending .dx-g-bs4-table:last-child {
  min-width: calc(100% + 3px)!important;
}

.pending .dx-g-bs4-table:first-child colgroup col:nth-child(1) {
  width: 110px !important;
}

.pending .dx-g-bs4-table:first-child colgroup col:nth-child(2) {
  width: 205px !important;
}

.pending .dx-g-bs4-table:last-child colgroup col:nth-child(1) {
  width: 85px !important;
}

.pending .dx-g-bs4-table:last-child colgroup col:nth-child(2) {
  width: 225px !important;
}

.count {
  position: absolute;
  z-index: 999;
  top: -55px;
  background: #333;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  line-height: 1;
  padding-top: 5px;
  padding-bottom: 5px;
}

.alert-heading {
  font-size: 18px;
  line-height: 20px;
}

.alert-heading span {
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -2px;
}

.buying.count {
  left: 155px;
  top: 25px;
}

.goat.count {
  left: 186px;
}

.products.count {
  left: 140px;
}

.pending.count {
  left: 135px;
}

.history.count {
  left: 125px;
}

@media only screen and (max-width: 768px) {
  .card-header .form-control.w-25 {
    min-width: 210px;
  }
  .dashboard h5 {
    padding-top: 20px;
  }
  .card {
    margin-left: -15px;
    width: calc(100% + 30px);
  }
  .dx-g-bs4-group-cell .dx-g-bs4-fixed-group-cell img {
    height: 40px;
  }
  .dx-g-bs4-group-cell .dx-g-bs4-fixed-group-cell a {
    max-width: 58px;
  }
  .dx-g-bs4-group-cell .dx-g-bs4-fixed-group-cell span span {
    font-size: 13px;
    font-weight: bold;
  }
  .dx-g-bs4-group-cell .dx-g-bs4-fixed-group-cell a::after {
    font-size: 10px;
    line-height: 40px;
  }
  header a[role="button"] {
    padding-left: 0px!important;
  }
  .export {
    right: 260px;
  }
  .history.count {
    left: 70px;
  }
  .buying.count {
    left: 65px;
  }
  .products.count {
    left: 88px;
  }
  .pending.count {
    left: 80px;
  }
  button[aria-label="open drawer"] {
    margin-right: -15px;
  }
  .sweet-alert {
    width: 90% !important;
  }
  .sweet-alert h2 {
    font-size: 15px!important;
  }
  .errorOutlineIcon {
    width: 40px !important;
    height: 40px!important;
  }
  div[role="presentation"] {
    /* z-index: 9999!important; */
  }
  h2 {
    width: calc(100% - 30px);
    margin-left: 15px;
    border-bottom: 1px solid #ccc;
    font-size: 1.5em;
  }
  .card .float-right.d-sm-none.mr-4 {
    display: block!important;
    top: 40px!important;
  }
  .dx-g-bs4-no-data-cell div big {
    position: fixed;
    left: calc(50% - 50px) !important;
  }
  .allBids {
    right: 0px;
    top: -30px;
    z-index: 1000;
  }
  .buying .allBids {
    right: 0px;
  }
  .products .allBids {
    right: 0px;
  }
  .all-visibleBtn {
    right: 260px;
  }
  .hideAddition .products.count {
    left: 153px;
  }
  .proxy.count {
    left: 80px;
  }
  .scheduler.count {
    top: 24px;
    left: 175px;
  }
}

.jss14 .jss15 img {
  width: 100%;
}

.Sidebar-drawerPaperMini-14 .Sidebar-logo-15 img {
  width: 100%;
}

.Sidebar-logo-15 img {
  min-width: 100%;
  width: 100%;
}

.Sidebar-drawerPaper-9, .jss9 {
  z-index: 9999!important;
}

.updateTime {
  position: relative;
  display: contents;
  width: 100%;
  margin-bottom: 10px;
}

.updateTime p {
  position: absolute;
  bottom: 0px;
  margin-bottom: 0px;
  font-weight: normal;
}

p[data-attr="run"] {
  position: relative;
}

p[data-attr="run"] i {
  width: 15px;
  height: 15px;
  margin: 0px;
  display: inline-block;
  border-radius: 50%;
  border: 0.3rem solid #17c001;
  -webkit-transform: translate(50%);
  transform: translate(50%);
  -webkit-animation: 1s ripple ease-out infinite;
  animation: 1s ripple ease-out infinite;
  top: 2px;
  position: relative;
}

@keyframes spin {
  to {
    transform: rotate(-360deg);
  }
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}

.updateTime p i {
  width: 15px;
  height: 15px;
  margin: 0px;
  display: inline-block;
  border-radius: 50%;
  border: 0.3rem solid #17c001;
  -webkit-transform: translate(50%);
  transform: translate(50%);
  -webkit-animation: 1s ripple ease-out infinite;
  animation: 1s ripple ease-out infinite;
  top: 3px;
  position: relative;
}

.updateTime span {
  position: absolute;
  width: 100%;
  text-align: right;
  bottom: 0px;
  right: 15px;
  font-weight: normal;
  color: #999;
  font-size: 13px;
}

.updateTime .material-icons.rotate {
  position: relative!important;
  width: auto!important;
  right: 0px!important;
  -webkit-animation: 1.5s spin infinite linear;
  animation: 1.5s spin infinite linear;
}

.rotate {
  position: relative!important;
  width: auto!important;
  right: 0px!important;
  -webkit-animation: 1.5s spin infinite linear;
  animation: 1.5s spin infinite linear;
}

.card .float-right.d-sm-none.mr-4 {
  display: block!important;
  margin-top: 0px;
  float: left !important;
  position: absolute;
  top: 15px;
  left: 60px;
  font-size: 13px;
}

.card .float-right.d-sm-none.mr-4::before {
  position: absolute;
  left: -60px;
  content: "Showing";
}

.updateTime .material-icons {
  position: relative!important;
  width: auto!important;
  right: 0px!important;
}

.card .table tbody tr .dx-g-bs4-no-data-cell {
  padding-bottom: 30px!important;
  height: 300px;
}

.dx-g-bs4-no-data-cell div {
  width: 100%;
  position: absolute;
  left: 0px;
}

.dx-g-bs4-no-data-cell div big {
  position: fixed;
  left: calc(50% + 100px);
}

.page-item.active.disabled .page-link {
  color: #fff;
  opacity: .5;
}

.removeAllProxy {
  position: absolute !important;
  right: 30px;
  top: 47px;
  z-index: 9;
  background: #ea256d !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-radius: 15px !important;
}

.tableList {
  position: relative;
}

.proxy form .form-group {
  position: relative;
  z-index: 99;
}

.yourFilter span{
  margin-bottom: 5px;
  display: inline-block;
}

.yourFilter table tbody td:first-child
{
 font-weight:normal!important;
padding-bottom:0px!important;
}

.yourFilter table
{
margin-top:10px!important;
border:1px solid #ddd;
}

.yourFilter table td
{
border:1px solid #ddd;
}

.yourFilter table td:last-child
{
text-align:center!important;
}

.yourFilter table thead tr
{
background:#f1f1f1;
}
.yourFilter table td:first-child
{
text-align:center!important;
}
.yourFilter table thead td
{
font-weight:bold!important;
padding-bottom: 0px !important;
}

.RowIdFormatter{
  cursor: pointer;
}

.BulkUpdate button.bulkUpdate{
  right: 120px;
  top: 90px;
}

.cancel-Btn
{
border:1px solid #ddd;
border-radius:15px;
background:#fff!important;
margin-left:10px;
margin-top:-15px;
font-size:11px;
min-width: 55px;
height: 25px;
}

.cancel-Btn:hover
{
background:#d81b60 !important;
color:#fff;
}

.cancel-Btn span
{
  margin-left: 0px!important;
  margin-top: -1px;
  height: 16px;
  font-size: 15px;
  line-height: 18px;
}

.globalUpdate .count{
  left: 185px;

}

.globalUpdate button.bulkUpdate{
  right: 120px;
  top: 90px;
}

.globalUpdate .reload {
  right: 80px;
  top: 93px;
}

.BrandScroll
{
   width:100%;
   max-height:100px;
   overflow-y:auto;
}

.stockxbtn{
  display: none !important;
}

.stock-x-rating {
  text-align: center;
  padding: 5px;
  color: #ffffff !important;
  font-weight: bold;
}

.stock-x-rating-1 {
  background-color: purple !important;
}

.stock-x-rating-2 {
  background-color: green !important;
}

.stock-x-rating-3 {
  background-color: yellow !important;
  color: #000 !important;
}

.stock-x-rating-4 {
  background-color: orange !important;
}

.stock-x-rating-5 {
  background-color: red !important;
}

.settings .reload {
  right: 40px;
  top: 15px
}

.goatstock .syncBuyNowBtn.reload {
  right: 110px;
}

.modeling-max-bid {
  margin-top: 5px;
}

.modeling-max-bid .max-bid-amount {
  margin-left: 2px;
}

.text-through {
  text-decoration: line-through;
}

.text-bold {
  font-size: 1.2em;
  font-weight: bold;
}

.dx-g-bs4-inactive:after {
  background-color: transparent;
}








